<template>
  <div class="package-item">
    <b-media class="text-left">
      <template v-slot:aside>
        <b-img-lazy
          v-bind="imgMainProps"
          blank-color="#ccc"
          :src="cover"
          alt="图片"
          rounded
        ></b-img-lazy>
      </template>
      <h6 class="mb-1">{{ title }}</h6>
      <div>成功率{{ subtitle }}</div>
      <b-badge> {{ tag }}</b-badge>
    </b-media>
  </div>
</template>

<script>
import { imageUrl } from "@/utils/image";

export default {
  name: "PackageItem",
  props: {
    package: { type: Object, default: null },
  },
  data() {
    return {
      imgMainProps: { blank: true, width: 76, height: 76, class: "mr-1" },
    };
  },
  computed: {
    cover() {
      return imageUrl(this.package.coverUrl || "");
    },
    title() {
      return this.package.orgName;
    },
    subtitle() {
      return "\t" + this.package.satisfyRate + "%";
    },
    tag() {
      return this.package.authName;
    },
  },
};
</script>

<style scoped lang="scss">
.package-item {
  font-size: 16px;

  img.rounded {
    object-fit: cover;
  }

  .badge.badge-secondary {
    background-color: orange;
  }
}
</style>
