<template>
  <div class="page">
    <head-banner></head-banner>

    <!-- <b-container v-if="isMobile">
      <b-row>
        <b-col class="px-0" offset-md="1" md="10" lg="7">
          <head-logo></head-logo>
        </b-col>
      </b-row>
    </b-container> -->
    <b-container>
      <!-- <div class="btn-consult text-center" @click="gotoContactus">
        <b-icon icon="chat" font-scale="2"></b-icon>
        <div>諮詢</div>
      </div> -->
      <b-row>
        <!-- <b-col offset-md="1" md="10" lg="7"> -->
        <b-col cols="12" lg="8">
          <div v-if="shownDetail" class="py-1">
            <div class="declaration py-2">
              聲明：所有套餐僅限法律允許地區推廣與銷售。
            </div>
            <h5 class="package__title mt-3">
              包成功捐卵助孕套餐介紹(台灣客戶)
            </h5>
            <div
              class="page-section pb-2"
              v-for="section in packageInfo"
              :key="section.head"
            >
              <div class="section__title py-3">{{ section.head }}</div>
              <dl v-if="section.ul">
                <dt v-for="ul in section.ul" :key="ul">
                  {{ ul }}
                </dt>
                <ul class="pt-1 pl-3" v-if="section.li">
                  <li v-for="li in section.li" :key="li">
                    {{ li }}
                  </li>
                </ul>
              </dl>
              <template v-if="section.tables">
                <b-table outlined :items="section.tables"></b-table>
              </template>
              <template v-if="section.footer">
                <div class="sign__area py-3">
                  <div class="sign__item">
                    <div class="item__left">
                      <div class="item__title">簽署:</div>
                      <div class="bottom__line"></div>
                    </div>
                    <div class="item__right pl-1">
                      <div class="item__title_1">第壹試管:</div>
                      <div class="bottom__line"></div>
                    </div>
                  </div>
                  <div class="sign__item">
                    <div class="item__left">
                      <div class="item__title">姓名:</div>
                      <div class="bottom__line"></div>
                    </div>
                    <div class="item__right pl-1">
                      <div class="item__title_1"></div>
                      <div class="bottom__line"></div>
                    </div>
                  </div>
                  <div class="sign__item">
                    <div class="item__left">
                      <div class="item__title">日期:</div>
                      <div class="bottom__line"></div>
                    </div>
                    <div class="item__right pl-1">
                      <div class="item__title_1"></div>
                      <div class="bottom__line"></div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div v-else>
            <template v-if="!isLoading && listData.length == 0">
              <page-error></page-error>
            </template>
            <template v-else>
              <div v-for="(item, index) in listData" :key="index">
                <package-item :package="item"></package-item>
              </div>
            </template>
          </div>
        </b-col>
      </b-row>
      <div class="pb-3">
        <div v-if="isLoading" class="text-center">
          <b-spinner variant="primary" label="加載中..."></b-spinner>
        </div>
        <div v-else-if="!canLoadMore" class="text-center">已顯示所有數據</div>
      </div>
    </b-container>
  </div>
</template>

<script>
import HeadBanner from "@/components/HeadLogo";
import PageError from "@/components/PageError";
import PackageItem from "./components/PackateItem";
import { getPackageList } from "@/utils/api/package";
import { mapState } from "vuex";

export default {
  name: "Package",
  components: { HeadBanner, PackageItem, PageError },
  data() {
    return {
      shownDetail: true,
      isScroll: true,
      isLoading: false,
      canLoadMore: true,
      currentPage: 1,
      pageSize: 10,
      totalPage: 1,
      listData: [],
      packageInfo: [],
    };
  },
  computed: {
    ...mapState({ isMobile: (state) => state.app.isMobile }),
  },
  methods: {
    // gotoContactus() {
    //   this.$router.push({
    //     name: "Contactus",
    //   });
    // },
    fetchListData() {
      if (this.isLoading) return;
      this.isLoading = true;

      const params = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        nationality: this.filterCountry,
        bloodType: this.filterBloodType,
        ageRange: this.filterAge,
        sex: this.filterGender,
      };
      getPackageList(params)
        .then((res) => {
          var list = res.data.data;
          if (res.code == 200 && list) {
            if (this.currentPage <= 1) {
              this.listData = [];
            }
            this.listData = this.listData.concat(list);
            this.canLoadMore = list.length >= this.pageSize;
            this.isLoading = false;
            this.totalPage = res.data.totalPage || 1;
            this.loadStatus = this.listData.length > 0 ? null : "nodata";
            this.currentPage++;
          }
          this.isScroll = true;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.loadStatus = "error";
          this.isScroll = true;
        });
    },
    fetchListDataByScroll() {
      if (!this.isMobile) return;
      // 滚动高度
      const scrollTopHeight =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 屏幕可用工作区高度
      const clientHeight =
        document.documentElement.clientHeight || window.screen.availHeight;
      // 网页可见区域高(包括边线的宽)
      const offsetHeight =
        document.documentElement.offsetHeight || document.body.offsetHeight;

      const isToBottom = scrollTopHeight + clientHeight + 60 >= offsetHeight;

      if (isToBottom && this.isScroll && this.canLoadMore && !this.isLoading) {
        this.isScroll = false;
        this.fetchListData();
      }
    },
  },
  created() {
    // this.fetchListData();
    let sectionA_title =
      "A.本人同意前往柬埔寨/吉爾吉斯進行試管嬰兒療程，以下是服務內容及付款細節:";
    let sectionA_ul =
      "1. 合同內容與柬埔寨/吉爾吉斯公司執行;2. 服務收費為新台幣2,231,000;3. 服務內容包括 :";
    let sectionA_li =
      "3.1 詳細解釋療程;3.2 設定時間表;3.3 與醫院預約;3.4 VIP套餐服務詳情; 3.5 療程的進展報告; 3.6 驗孕安排;3.7 寶寶出生安排";
    let sectionB_title = "B.服務收費的細節如下:";
    let sectionC_title = "C.VIP套餐服務詳情:";
    let sectionC_ul =
      "1.療程期間翻譯員;2.療程期間的陰道超聲波檢查; 3.無限次捐卵者取卵手術; 4.捐卵者取卵手術的麻醉費用; 5.全部胚胎的植入前染色體分析; 6.療程期間著床藥;7.無限次胚胎移植; 8.療程期間的驗血費用; 9.療程期間的排卵針; 10.療程期間的安胎針; 11.療程期間的醫生費用; 12.療程期間的實驗室費用; 13.懷孕過程安胎護理，保姆照顧及產檢; 14.寶寶出生費用及出生證明文件辦理";
    let sectionD_title = "D.療程費用不包括:";
    let sectionD_ul =
      "1. 雙胞胎另加新台幣223,000;2. 當地膳食及旅遊費用;3. 寶寶早產所產生的醫療費用; 4. 洗精費用另加新台幣134,000; 5. 指定性別另加新台幣223,000";
    let sectionE_title = "E.備註:";
    let sectionE_ul =
      "1. 客戶需接受第三方輔助生育技術。; 2. 吉爾吉斯的試管服務屬合法。; 3. 客戶必須在嬰兒出生時前往吉爾吉斯一次，以便安排寶寶出生證明和出境許可證明文件。; 4. 如未能成功懷孕或在懷孕過程中流產，將無限次接受胚胎移植。;5. 如第一次捐卵者所配對的所有胚胎已全部移植完後還沒有成功懷孕，將再次安排另一位捐卵者進行試管療程，客人期間不需要再支付額外費用。;6. 合約期內，客戶以任何原因而取消上述療程計畫，客戶所付費用將不會退還。";
    let sectionF_title = "F.男方所需條件:";
    let sectionF_ul =
      "1. 男方 : 精子常规，形态和精子DNA精子碎片报告在正常范围客戶事前需提供3個月內於國內三甲醫院或本公司指定醫院的精子檢查報告，項目如下:;男方: (先排一次精，72小时后去检查)";
    let sectionF_li =
      "1.1 精液分量;1.2 精子数量;1.3 精子活动率;1.4 精子等级;1.5 精子正常/畸形率!";
    let table_items = [
      { age: "1", first_name: "訂金", last_name: "新台幣45,000" },
      {
        age: "2",
        first_name: "取精",
        last_name: "新台幣179,000",
      },
      {
        age: "3",
        first_name: "確認捐卵者及開始卵泡刺激素注射",
        last_name: "新台幣446,000",
      },
      { age: "4", first_name: "取卵手術", last_name: "新台幣223,000" },
      {
        age: "5",
        first_name: "移植",
        last_name: "新台幣446,000",
      },
      {
        age: "6",
        first_name: "成功懷孕及見胎兒心跳",
        last_name: "新台幣223,000",
      },
      { age: "7", first_name: "懷孕三個月", last_name: "新台幣223,000" },
      { age: "8", first_name: "懷孕六個月", last_name: "新台幣223,000" },
      { age: "9", first_name: "寶寶出生", last_name: "新台幣223,000" },
      { age: "", first_name: "總共費用", last_name: "新台幣2,231,000" },
    ];

    this.packageInfo = [
      {
        head: sectionA_title,
        ul: sectionA_ul.split(";"),
        li: sectionA_li.split(";"),
        footer: false,
      },
      {
        head: sectionB_title,
        tables: table_items,
      },
      {
        head: sectionC_title,
        ul: sectionC_ul.split(";"),
      },
      {
        head: sectionD_title,
        ul: sectionD_ul.split(";"),
      },
      {
        head: sectionE_title,
        ul: sectionE_ul.split(";"),
      },
      {
        head: sectionF_title,
        ul: sectionF_ul.split(";"),
        li: sectionF_li.split(";"),
      },
    ];
  },
  mounted() {
    // document.addEventListener("scroll", this.fetchListDataByScroll, false);
  },
  destroyed() {
    document.removeEventListener("scroll", this.fetchListDataByScroll, false);
  },
};
</script>

<style scoped lang="scss">
.page {
  margin-bottom: 60px;

  .declaration {
    color: var(--red);
  }

  .package__title {
    color: #1fb9de;
  }

  .section__title {
    font-weight: 1.5em;
    font-weight: bold;
  }

  dt,
  dd {
    font-size: 17px;
    font-weight: normal;
  }

  ul {
    list-style-type: none;
    font-size: 17px;
  }

  .sign__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // background-color: purple;
    width: 100%;

    .item__left {
      width: 45%;
    }
    .item__right {
      width: 55%;
    }

    .item__left,
    .item__right {
      display: flex;
      flex-direction: row;
      padding: 0.25em 0;

      .item__title {
        width: 4em;
      }
      .item__title_1 {
        width: 8em;
      }

      .bottom__line {
        width: 100%;
        border-bottom: 1px solid #333;
      }
    }
  }

  // .btn-consult {
  //   cursor: pointer;
  //   position: fixed;
  //   z-index: 980;
  //   bottom: 16vh;
  //   right: 5vw;
  //   background-color: #1fb9de;
  //   color: #fff;
  //   font-size: 0.75rem;
  //   width: 70px;
  //   height: 70px;
  //   border-radius: 50%;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  // }
}
</style>

<style>
.table th {
  display: none !important;
}

.table td {
  padding: 8px 5px;
  font-size: 0.8rem;
  font-weight: bold;
}
</style>
